import InuptIcon from '@/app/_components/Icon/Inupt'
import I18nTranslatorClient from '@/app/_components/Shared/I18nTranslator/I18nTranslatorClient'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/app/_primitives/Popover'
import { cn } from '@/app/utils'
import { useCurrentLocale } from '@/locales/client'
import Link from 'next/link'
import { useState } from 'react'

export default function SolutionMenu() {
  const [open, setOpen] = useState(false)
  const handleMouseEnter = () => setOpen(true)
  const isEnglish = Boolean(useCurrentLocale() === 'en')
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild onMouseEnter={handleMouseEnter}>
        <div
          className={cn(
            'relative flex cursor-pointer items-center hover:text-butterscotch',
            { 'text-butterscotch': open },
          )}>
          <I18nTranslatorClient text={'common.solution'} />
          <button type="button" className="relative size-24">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.11716 11.6087C9.27337 11.4638 9.52663 11.4638 9.68284 11.6087L12 13.758L14.3172 11.6087C14.4734 11.4638 14.7266 11.4638 14.8828 11.6087C15.0391 11.7536 15.0391 11.9885 14.8828 12.1334L12.5657 14.2827C12.2533 14.5724 11.7467 14.5724 11.4343 14.2827L9.11716 12.1334C8.96095 11.9885 8.96095 11.7536 9.11716 11.6087Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </PopoverTrigger>
      <PopoverContent
        align={'start'}
        onMouseLeave={() => setOpen(!open)}
        className={cn(
          'mt-20 flex w-212 flex-col items-stretch rounded-xl bg-white p-8',
          {
            'w-260': isEnglish,
          },
        )}>
        <div className="c-b3 rounded-15 px-12 py-8 hover:bg-french-bleu/10">
          <Link href="/ip-care" className="flex size-full items-center gap-8">
            <I18nTranslatorClient text={'common.ipcare'} />
          </Link>
        </div>
        <div className="c-b3 rounded-15 px-12 py-8 hover:bg-french-bleu/10">
          <Link
            href="https://inupt.io/"
            className="flex size-full items-center gap-8"
            target="_blank">
            <I18nTranslatorClient text={'common.goods_production'} />
            <InuptIcon />
          </Link>
        </div>
        <div className="c-b3 rounded-15 px-12 py-8 hover:bg-french-bleu/10">
          <Link
            href="https://gm.inabooth.io/"
            className="flex size-full items-center gap-8"
            target="_blank">
            <I18nTranslatorClient text={'common.goods_management'} />

            <span className="flex h-18 w-35 items-center justify-center rounded-5 bg-strawberry-milk text-11 font-bold text-cherry">
              Beta
            </span>
          </Link>
        </div>
      </PopoverContent>
    </Popover>
  )
}
