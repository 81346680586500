'use client'

import { Sheet, SheetContent, SheetTrigger } from '@/app/_components/ui/sheet'

import ChattingAlarm from '@/app/_components/Icon/ChattingAlarm'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/app/_components/ui/popover'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { useUnreadConversationsCount } from '@/app/_messenger/hooks/useUnreadConversationCount'
import { useWebSocket } from '@/app/_messenger/hooks/useWebSocket'
import { PopupMessenger } from '@/app/_messenger/popup/PopupMessenger'
import { useMediaQuery } from 'react-responsive'
import { Intent } from '../common/Intent'

function Icon({ unreadCount }: { unreadCount: number }) {
  return (
    <div className="relative flex items-center justify-center gap-8 xl:size-40">
      <ChattingAlarm />
      <span className="xl:hidden">채팅 문의</span>
      {unreadCount > 0 && (
        <span className="absolute bottom-1 right-0 flex size-13 items-center justify-center rounded-full bg-cherry font-noto-sans-kr text-9 font-bold text-white">
          {unreadCount}
        </span>
      )}
    </div>
  )
}

export default function PopupMessengerSheet({
  connectionURL,
}: {
  connectionURL: string
}) {
  useWebSocket(connectionURL)
  const [{ open }, setState] = useMessengerState()
  const { data: unreadConversationsCount = 0 } =
    useUnreadConversationsCount(true)

  const isXl = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const onOpenChange = (o: boolean) =>
    setState((draft) => {
      draft.open = o
    })

  const handleClose = () => {
    onOpenChange(false)
  }

  return (
    <Intent intent={'default'}>
      <div className="contents xl:hidden">
        <Sheet open={open && !isXl} onOpenChange={onOpenChange}>
          <SheetTrigger>
            <Icon unreadCount={unreadConversationsCount} />
          </SheetTrigger>
          <SheetContent side="top" className={'h-screen'}>
            <PopupMessenger onClose={handleClose} />
          </SheetContent>
        </Sheet>
      </div>
      <div className="hidden xl:contents">
        <Popover open={open && isXl} onOpenChange={onOpenChange} modal>
          <PopoverTrigger>
            <Icon unreadCount={unreadConversationsCount} />
          </PopoverTrigger>
          <PopoverContent className="mr-40 mt-20 h-552 w-422 bg-[#2559AA]">
            <PopupMessenger onClose={handleClose} />
          </PopoverContent>
        </Popover>
      </div>
    </Intent>
  )
}
