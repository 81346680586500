'use client'

import ChevronDown from '@/app/_components/Icon/ChevronDown'
import InuptIcon from '@/app/_components/Icon/Inupt'
import I18nTranslatorClient from '@/app/_components/Shared/I18nTranslator/I18nTranslatorClient'
import PopupNotMobileGms from '@/app/_messenger/popup/PopupNotMobileGms'
import Link from 'next/link'
import { useState } from 'react'

export function MobileSolutionMenu() {
  const [isSolutionOpen, setIsSolutionOpen] = useState(false)
  return (
    <div
      className="flex flex-col border-b-1 border-b-[#74A4EE4D] py-12"
      onClick={() => setIsSolutionOpen((prev) => !prev)}>
      <div className="c-b3 flex list-none items-center gap-4">
        {/* <span>{t('common.licensingLabel')}</span> */}
        <span className={isSolutionOpen ? 'text-butterscotch' : ''}>
          솔루션
        </span>
        <div
          className={`scale-50 ${isSolutionOpen ? 'rotate-180' : 'rotate-0'}`}>
          <ChevronDown fill={isSolutionOpen ? '#fdcc53' : 'white'} />
        </div>
      </div>
      <div
        className={`${isSolutionOpen ? 'block' : 'hidden'} flex flex-col gap-8 px-8 pt-8`}>
        <Link href="/ip-care">
          <span className="c-b3 flex items-center gap-3 hover:underline">
            <I18nTranslatorClient text={'common.ipcare'} />
          </span>
        </Link>
        <Link href="https://inupt.io/" target="_blank">
          <span className="c-b3 flex items-center gap-3 hover:underline">
            굿즈 제작 <InuptIcon />
          </span>
        </Link>
        <PopupNotMobileGms />
      </div>
    </div>
  )
}
