import { ReactNode, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type Options = {
  id: string
  content: any
}

type Props = {
  children: ReactNode
  options: Options[]
  className?: string
  onSelect?: any
  selected: Options
  popOverClassName?: string
}

function doesContain(target: HTMLElement, container: HTMLElement) {
  if (target === null) return false

  if (target.parentNode === container) {
    return true
  }

  return doesContain(target.parentNode as HTMLElement, container)
}

export default function Dropdown({
  children,
  options,
  className,
  selected,
  onSelect,
  popOverClassName,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside: EventListenerOrEventListenerObject = (
      e: Event,
    ) => {
      if (
        isOpen &&
        ref.current &&
        !doesContain(e.target as HTMLElement, ref.current) &&
        buttonRef.current !== e.target
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [isOpen])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={`relative h-60 w-full font-medium`}>
      <div
        aria-hidden
        ref={buttonRef}
        onClick={toggleDropdown}
        className={twMerge(
          'c-b5 flex h-60 w-full cursor-pointer items-center justify-between rounded-15 px-12 py-10',
          `${className}`,
        )}>
        {selected ? selected.content : children}

        <div
          className={`${
            isOpen ? 'rotate-180 duration-300' : 'rotate-0 duration-300'
          }`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.79289 8.54289C5.18342 8.15237 5.81658 8.15237 6.20711 8.54289L12 14.3358L17.7929 8.54289C18.1834 8.15237 18.8166 8.15237 19.2071 8.54289C19.5976 8.93341 19.5976 9.56658 19.2071 9.9571L13.4142 15.75C12.6332 16.531 11.3668 16.531 10.5858 15.75L4.79289 9.95711C4.40237 9.56658 4.40237 8.93342 4.79289 8.54289Z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
      </div>
      <div
        ref={ref}
        className={`relative z-10 mt-4 overflow-y-auto rounded-15 bg-[#3B6AB2] ${popOverClassName} ${
          isOpen ? `p-12 xl:shadow-md` : 'max-h-0 !border-0'
        } `}>
        {options.map((item) => (
          <div
            aria-hidden
            className={`cursor-pointer ${
              !popOverClassName ? 'text-white' : ''
            }`}
            id={String(item.id)}
            onClick={() => {
              onSelect(item)
              setIsOpen(false)
            }}
            key={item.id}>
            {item.content}
          </div>
        ))}
      </div>
    </div>
  )
}
