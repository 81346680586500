'use client'

import { Button } from '@/app/_primitives/Button'
import { useI18n } from '@/locales/client'
import Link from 'next/link'

export default function CharacterRegisterBtn() {
  const t = useI18n()
  return (
    <Button
      asChild
      type="button"
      size="sm"
      color="milkyWhite"
      className="c-b3 flex items-center justify-center max-xl:w-full xl:!h-42 xl:py-0">
      <Link href={'/characters/create'}>{t('common.regCharacterLabel')}</Link>
    </Button>
  )
}
