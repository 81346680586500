'use client'

import HeaderLanguageDropdown from '@/app/_components/Shared/HeaderLanguageDropdown'
import { useChangeLocale, useCurrentLocale } from '@/locales/client'
import Image from 'next/image'
import Dropdown, { Options } from './Shared/Dropdown'
import EnglishFlag from './etc.svg'
import JapanFlag from './japanese.svg'
import KoreanFlag from './korean.svg'

const languages = [
  {
    id: 'ko',
    content: (
      <div className="flex items-center gap-8 px-8 py-12">
        <Image
          src={KoreanFlag}
          alt={'KoreanFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20"
        />
        <p>한국어</p>
      </div>
    ),
  },
  {
    id: 'ja',
    content: (
      <div className="flex items-center gap-8 px-8 py-12">
        <Image
          src={JapanFlag}
          alt={'JapanFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20"
        />
        <p>日本語</p>
      </div>
    ),
  },
  {
    id: 'en',
    content: (
      <div className="flex items-center gap-8 px-8 py-12">
        <Image
          src={EnglishFlag}
          alt={'EnglishFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20"
        />
        <p>English</p>
      </div>
    ),
  },
]

const headerLanguages = [
  {
    id: 'ko',
    content: (
      <div className="flex items-center gap-8 ">
        <Image
          src={KoreanFlag}
          alt={'KoreanFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20 rounded-full border-1 border-tertiary"
        />
        <p className="c-b3">KR</p>
      </div>
    ),
  },
  {
    id: 'ja',
    content: (
      <div className="flex items-center gap-8 ">
        <Image
          src={JapanFlag}
          alt={'JapanFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20 rounded-full border-1 border-tertiary"
        />
        <p className="c-b3">JP</p>
      </div>
    ),
  },
  {
    id: 'en',
    content: (
      <div className="flex items-center gap-8 ">
        <Image
          src={EnglishFlag}
          alt={'EnglishFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20 rounded-full border-1 border-tertiary"
        />
        <p className="c-b3">EN</p>
      </div>
    ),
  },
]

export default function LanguageSwitcher({
  type = 'footer',
}: {
  type?: 'header' | 'footer'
}) {
  const changeLocale = useChangeLocale()
  const typeLanguages = type === 'header' ? headerLanguages : languages
  const currentLanguage = typeLanguages.filter(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (lang) => lang.id === useCurrentLocale(),
  )[0]

  return (
    <>
      {type === 'header' ? (
        <HeaderLanguageDropdown
          className=""
          options={typeLanguages}
          selected={currentLanguage}
          onSelect={(option: Options) => {
            changeLocale(option.id as 'ko' | 'ja' | 'en')
          }}>
          Language
        </HeaderLanguageDropdown>
      ) : (
        <Dropdown
          className="!c-b3 h-46 w-full bg-[#3B6AB2] font-[500] text-white max-xl:w-186"
          options={typeLanguages}
          selected={currentLanguage}
          onSelect={(option: Options) => {
            changeLocale(option.id as 'ko' | 'ja' | 'en')
          }}>
          Language
        </Dropdown>
      )}
    </>
  )
}
