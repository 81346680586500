'use client'

import SolutionMenu from '@/app/_components/Header/_components/SolutionMenu'
import I18nTranslatorClient from '@/app/_components/Shared/I18nTranslator/I18nTranslatorClient'
import { useI18n } from '@/locales/client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import Trend from '../Icon/Trend'

export default function HeaderLinks() {
  const pathname = usePathname()
  const t = useI18n()

  return (
    <div className="flex items-center gap-32 text-white xl:gap-18 2xl:gap-32">
      <Link
        className={`hover:text-butterscotch ${
          pathname.match('/characters') ? 'text-butterscotch' : ''
        }`}
        href="/characters">
        <I18nTranslatorClient text={'common.header_search'} />
      </Link>

      <Link
        className={`flex items-center hover:text-butterscotch ${
          pathname.match('/collabo') || pathname.match('/event')
            ? 'text-butterscotch'
            : ''
        }`}
        href="/collabo">
        <I18nTranslatorClient text={'common.collaboLabel'} />
      </Link>

      <Link
        className="flex items-center justify-center gap-5 fill-white hover:fill-butterscotch hover:text-butterscotch"
        href="https://trend.inabooth.io/">
        {t('common.trendLabel')}
        <Trend />
      </Link>

      <SolutionMenu />
    </div>
  )
}
