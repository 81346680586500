import { SvgIconProps } from '@/types/helpers'

export default function Inbox(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V13.1468C2 13.0976 2.00363 13.0485 2.01083 13C2.01788 12.9525 2.02834 12.9055 2.04217 12.8594L4.35861 5.13796C4.7393 3.869 5.90727 3 7.23209 3H16.7679C18.0927 3 19.2607 3.869 19.6414 5.13796L21.9578 12.8594C21.9717 12.9055 21.9821 12.9525 21.9892 13C21.9964 13.0485 22 13.0976 22 13.1468V18ZM16.7679 5H7.23209C6.79048 5 6.40116 5.28967 6.27427 5.71265L4.28116 12.3563C4.18492 12.6771 4.42515 13 4.76008 13H8.38197C8.76074 13 9.107 13.214 9.27639 13.5528L9.72084 14.4417C9.89023 14.7805 10.2365 14.9945 10.6153 14.9945H13.3847C13.7635 14.9945 14.1098 14.7805 14.2792 14.4417L14.7236 13.5528C14.893 13.214 15.2393 13 15.618 13H19.2399C19.5749 13 19.8151 12.6771 19.7188 12.3563L17.7257 5.71265C17.5988 5.28967 17.2095 5 16.7679 5Z"
        fill="currentFill"
      />
    </svg>
  )
}
